$small-font-size: 10px;
$standard-font-size: 20px;
$large-font-size: 30px;
$blue1: #DFE5F7;
$blue2: #8295A0;
$blue3: #738897;
$blue4: #608093;
$blue5: #517285;
$blue6: #37454F;
$blue7: #26333A;
$blue8: #171F24;
$font-family: 'Droid Sans', sans-serif;