.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .logo {
        position: relative;
  
        .logo-main {
            position: relative;
            // margin: 0 auto;
            z-index: 2;
        }
    }
}