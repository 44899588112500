@import "_common";

.App {
  background-color: $blue8;  
  color: $blue1;
  font-family: $font-family;
  text-align: center;
  height: 100vh;
  // display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


