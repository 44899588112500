.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .content {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
}